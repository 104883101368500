import React from "react";
import Layout from "../components/layout";
import StandardHeader from "../assets/elements/StandardHeader";
import ThreeColumnUseCaseSection from "../assets/elements/ThreeColumnUseCaseSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import Cta from "../partials/Cta";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import imgSarAutoSegmentationWebm from "../assets/images/sar-auto-segmentation.webm";
import {
  ChatIcon,
  CogIcon,
  CubeIcon,
  SparklesIcon,
  ChartPieIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import VideoComponent from "../components/VideoComponent";

const useCasesImageHeight = 198;
const useCasesImageWidth = 352;

const useCases = [
  {
    name: "Annotate",
    description: "Multi-functional annotation tools, all in one platform.",
    bullets: ["Classification", "Object detection", "Segmentation"],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/uses-smart-cities.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Annotate"}
      />
    ),
  },
  {
    name: "Review",
    description: "Customise review workflows to ensure maximal quality.",
    bullets: [
      "Per-label reviews",
      "Easily resolve label conflicts",
      "Monitor annotator quality statistics",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/review-json.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Png review json"}
      />
    ),
  },
  {
    name: "Automate",
    description: "Automate annotation & review to save human time.",
    bullets: [
      "Quality assurance automation",
      "Model-assisted labeling",
      "Object tracking & interpolation",
    ],
    image: (
      <VideoComponent
        loop
        autoPlay
        muted
        playsinline
        width={useCasesImageWidth}
        height={useCasesImageHeight}
      >
        <source src={imgSarAutoSegmentationWebm} type="video/webm" />
      </VideoComponent>
    ),
  },
];

const featuresGrid = [
  {
    name: "Flexible tools",
    description:
      "Our software supports a wide variety of visual modalities. Our editor tools facilitate efficient drawing, editing, and reviewing annotations.",
    icon: CubeIcon,
  },
  {
    name: "Automated image and video labeling",
    description:
      "Use our object tracking & interpolation features to reduce costs. Use micro-models to accelerate your active learning workflows and get to production faster.",
    icon: LightningBoltIcon,
  },
  {
    name: "Configurable ontology",
    description:
      "Create arbitrarily rich nested labeling structures accommodating all label modalities in one place.",
    icon: CogIcon,
  },
  {
    name: "Quality",
    description:
      "Construct customised label review workflows to ensure the highest label quality standards possible.",
    icon: SparklesIcon,
  },
  {
    name: "Workforce integration",
    description:
      "Maintain maximal flexibility by scaling your annotation teams to arbitrarily large sizes through our workforce partners.",
    icon: ChatIcon,
  },
  {
    name: "Visualise",
    description:
      "Visualise the breakdown of your labels in high granularity to get precise estimations of your label quality, annotation efficiency, and model performance.",
    icon: ChartPieIcon,
  },
];

const AnnotationTeams = ({ location }) => {
  return (
    <Layout location={location}>
      {/*  Page illustration */}
      <LeftColours />
      <RightColours />

      <StandardHeader
        title={"AI-powered tools to streamline and scale data annotation"}
        description={
          "Our intuitive editor tools makes manual annotations and reviews a piece of cake. Use our automation features to drastically reduce manual workloads."
        }
      />

      {/* Use cases */}
      <ThreeColumnUseCaseSection
        caseStudy={null}
        title={"How it works"}
        header={"Optimize human orchestration and improve ML workflows"}
        description={
          <>
            Pivot precious human time from drawing new labels to reviewing
            automated ones.
          </>
        }
        useCases={useCases}
      />

      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={"Features"}
        header={"Annotate, collaborate, and automate"}
        description={
          "Manage massive-scale labeling operations for computer vision."
        }
        features={featuresGrid}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={"Invest"}
        header={"Our investors 2"}
        description={"Our investors"}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../assets/images/uses-platform.png"}
            width={900}
            alt="Uses platform"
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../assets/images/flower-segmentation.png"}
            width={900}
            alt="Flower segmentation"
          />
        }
        headerOne={"Reduce ambiguity"}
        headerTwo={"Accelerated manual annotation"}
        descriptionOne={
          "Clearly defined label structures reduce annotator uncertainty leading to higher quality results. Real-time resolution of label conflicts result in high workforce utilisation."
        }
        descriptionTwo={
          "Simple, easy-to-use, and flexible drawing tools. Turbo-mode to quickly assign attributes to labels. Automated task distribution & navigation."
        }
      />

      <Cta
        location={location}
        pageName={"annotation teams page"}
        ctaText={"A powerful platform for annotation teams"}
      />
    </Layout>
  );
};

export default AnnotationTeams;

export const Head = () => (
  <SEO
    title="The Annotation Team Platform for Computer Vision | Encord"
    description="Encord's annotation tools improve quality, speed up annotation workflows, and reduce manual workloads."
  />
);
